import React, { Component} from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";

function handleClick(evt) {
    document.querySelector('[id="contact"]').scrollIntoView();
  }
  


class Header extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }

    
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }
    
    render(){


        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/header logo.png" alt="Magnificent Moments" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/header logo.png" alt="Magnificent Moments" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/header logo.png" alt="Magnificent Moments" />;
        }else if(logo === 'all-dark'){
            logoUrl = <img src="/assets/images/logo/header logo.png" alt="Magnificent Moments" />;
        } else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/header logo.png" alt="Magnificent Moments" />;
        }else{
            logoUrl = <img src="/assets/images/logo/header logo.png" alt="Magnificent Moments" />;
        }
        
        return(
            <header className={`header-area header--fixed formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                        <ul className="mainmenu">
                                            <li><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                            <li><Link to="/promotions" >Promotions</Link></li>
                                            <li><Link to="/wedding" >Weddings</Link></li>
                                            <li><Link to="/family" >Family</Link></li>
                                            <li><Link to="/graduation" >Graduation</Link></li>
                                            <li><Link to="/videography" >Videography</Link></li>
                                            {/* <li><Link to="/resources" >Resources</Link></li> */}
                                            <li><Link to="/contact" >Contact</Link></li>
                                        </ul>
                        </nav>
                        {/* <div className="header-btn d-none d-sm-block">
                                        <a className="btn-default btn-border btn-opacity" onClick={e => handleClick(e)} style={{cursor: "pointer"}}>
                                            <span>Book Now</span>
                                        </a>
                                    </div> */}
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
                
            </header>
        )
    }
}
export default Header;