import React, { Component } from "react";
import { FiCamera, FiVideo, FiFilm, FiPrinter,FiImage, } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCamera />,
        title: 'Photography',
        description: 'Capture your most cherished moments with our photography service'
    },
    {
        icon: <FiVideo />,
        title: 'Videography',
        description: 'Bring your vision to life with our creative storytelling through our video service'
    },
    {
        icon: <FiVideo />,
        title: 'Drone Photography',
        description: 'See the world from a different perspective with our drone video service'
    },
    {
        icon: <FiPrinter />,
        title: 'Photo Printing',
        description: 'Create memorable keepsakes that last with our premium photo printing service'
    },
    {
        icon: <FiImage />,
        title: 'Photo Editing',
        description: 'We enhance the beauty of your photos with our proessional editing service'
    },
    {
        icon: <FiFilm />,
        title: 'Video Editing',
        description: 'Unlock the full potential of your footage with our expert video editing service'
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services we offer',
        description = 'Discover our comprehensive range of professional photography services <br /> tailored to meet your needs.',
        subtitle= 'What we can do for you';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a>
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
