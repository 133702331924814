import React, { Component } from "react";
import { FiVideo, FiFilm} from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiVideo />,
        title: 'Event Videography',
        description: 'We capture and document special events such as weddings, concerts, and parties'
    },
    {
        icon: <FiVideo />,
        title: 'Commercial Videography',
        description: 'We produce videos for businesses and brands to promote their products and services'
    },
    {
        icon: <FiVideo />,
        title: 'Corporate Videography',
        description: 'We provide video production services for corporate clients, including training videos and more'
    },
    {
        icon: <FiVideo />,
        title: 'Documentary Filmmaking',
        description: 'We help esearch, plan, shoot, and edit documentaries on various subjects such as cultural topics'
    },
    {
        icon: <FiVideo />,
        title: 'Tutorial Videos',
        description: 'We create instructional videos for online courses, e-learning platforms, training programs, or tutorial series.'
    },
    {
        icon: <FiFilm />,
        title: 'Video Editing',
        description: 'We offer post-production services, including video editing, color grading, audio mixing, and motion graphic'
    },

]

class ServiceTwo extends Component{
    render(){
        let title = 'Services we offer',
        description = 'Discover our comprehensive range of professional videography services <br /> tailored to meet your needs.',
        subtitle= 'What we can do for you';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a>
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
