import PropTypes from "prop-types";
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle} - Birmingham Photography </title>
                    <meta name="description" content={this.props.description} />
                    <meta name="keywords" content={this.props.keywords} />
                    <link rel="canonical" href={this.props.canonical} />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-153644967-1"></script>
                            <script>
                                {
                                    `window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
        
                                    gtag('config', 'UA-153644967-1');`
                                }
                            </script>

                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string,
    // description: PropTypes.string,
    // keyword: PropTypes.string,
};
export default PageHelmet;
