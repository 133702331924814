import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Contact from "../elements/contact/ContactThree";
import Breadcrumb from "../elements/common/BreadcrumbFamilyGallery";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const TabOne = [
    {
        image: '1',
    },
    {
        image: '2',
    },
    {
        image: '3',
    },
    {
        image: '4',
    },
    {
        image: '5',
    },
    {
        image: '6',
    },
    {
        image: '7',
    },
    {
        image: '8',
    },
    {
        image: '9',
    },
    {
        image: '10',
    },
    {
        image: '11',
    },
    {
        image: '12',
    },
    {
        image: '13',
    },
    {
        image: '14',
    },
    {
        image: '15',
    },
    {
        image: '16',
    },
    {
        image: '17',
    },
    {
        image: '18',
    },
]


class HomePortfolio extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tab1: 0,
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { tab1, isOpen } = this.state;
        return(
            <div>
                <Helmet pageTitle="Family Gallery" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Family Gallery'} />
                {/* End Breadcrump Area */}

                <div className="page-wrapper">
                    {/* Start Portfolio Area  */}
                <div className="rn-portfolio-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <span className="subtitle">Completed photos</span>
                                        <h2 className="title">Family Gallery</h2>
                                        <p className="description">Step into a world where moments come alive through the lens</p>
                                    </div>
                                </div>
                        </div>
                        <div className="row">
                            {TabOne.map((value , index) => (
                                <div className="col-lg-6" key={index}>
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={TabOne[tab1].bigImage}
                                            nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                            prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                            onMovePrevRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                            })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    tab1: (tab1 + 1) % TabOne.length,
                                                })
                                            }
                                        />
                                    )}
                                    <div className="item-portfolio-static">
                                        <div>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <a>
                                                            <img src={`/assets/images/portfolio/family/familygallerysm-${value.image}.jpg`} alt="Portfolio Images"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}
                </div>

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--5">
                    <Contact />
                </div>
                {/* End Contact Page Area  */}


                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </div>
            
        )
    }
}
export default HomePortfolio;