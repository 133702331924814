import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { FiCheck } from "react-icons/fi";

class Accordion01 extends Component {
    render(){
        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
        ];

        return(
            <Accordion className="accodion-style--1" preExpanded={'0'}>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Cancellation / Postponement Explained
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Cancellation/postponement of the event can provide coverage for certain deposits listed in the policy in the event of a necessary and unavoidable wedding cancellation or postponement. For example, if the venue suddenly goes out of business and is inaccessible, or sudden illness prevents the honorees, parents, grandparents or children of honorees from attending, or an honoree is unexpectedly called to duty, this coverage can reimburse you for the deposits lost.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Lost Deposits Explained
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Lost deposits can provide coverage if a vendor goes out of business, declares bankruptcy before your event, or simply fails to show up. For example, if your band or your limo provider fails to show up, this coverage can reimburse your lost deposit.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Additional Expense Explained
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Additional expense can provide coverage for certain unexpected expenses you have to incur in order to avoid your wedding cancellation or postponement. For example, if a caterer or transportation company suddenly becomes unavailable for your event and you can find a last minute replacement, this coverage can reimburse you for the difference in cost up to 25% of the original contract price.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Event Photographs & Video Explained
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Event photographs & video can provide coverage in the event your photographer’s film is defective or photos are lost or damaged. For example, if your photographer fails to show up, or loses your photos from your wedding, this coverage can reimburse you for the reasonable expenses of reconvening your wedding party to take new photos or video. 
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Event Gifts Explained
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Event gifts can provide coverage if your gifts are damaged. For example, if your gifts are somehow damaged while on display at the event, this coverage can reimburse you for repair or replacement cost.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Special Attire Explained
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Special attire can provide coverage if the honoree’s event attire is lost or damaged. For example, if you’re traveling to your destination event and the airline loses your event attire, this coverage can reimburse you for the replacement attire.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Special Jewelry Explained
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Special jewelry can provide coverage for loss or damage to jewelry purchased or rented specifically for personal decoration of honorees on the event date or within 7 days prior to the event date.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Who can qualify for wedding insurance?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Residents of U.S. may purchase coverage. The Insured/Policyholder must be at least 18 years of age. An honoree will always be defined as an insured on the policy but may or may not be the named insured. The Named Insured can be either of the honorees or someone such as a parent or other family member that has a financial stake in the wedding.
                            <br/><br/><i>Please note: The Wedding Protector Plan is currently not available to residents of Alaska, Hawaii, & Louisiana. </i>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Why should I get wedding insurance?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Should you experience a covered loss, your non-recoverable costs can be as much, if not more, than the cost of an automobile. The quality of your wedding may depend upon your decision to insure against financial loss. For example, you buy insurance to provide a replacement automobile against unfortunate mishaps. Why not protect wedding like you would your other valuable investments?  
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What does the basic policy insure?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        The basic policy Core Coverage can reimburse you in case you lose your non-recoverable deposits and purchases if covered circumstances beyond your control cause you to cancel or postpone your wedding. You can also be protected against no-show or bankrupt vendors, damage to wedding attire, damaged gifts, and much more.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Does the Wedding Protector Plan private event insurance cover “change of heart”?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            The Private Event Insurance Policy does not provide coverage in the event that either the bride or groom changes their mind and does not want to proceed with the wedding.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What if I have already signed contracts and put down deposits for the wedding? Will those be covered?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Yes, deposits you have already made will typically be covered as long as there is no impending claim or existing claim situation that you have knowledge of when you purchase the policy.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Many people are helping to pay for the wedding. Will their deposits be protected?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Deposits of the honoree(s) and their “immediate family” could be covered. “Immediate family” is defined in the Special Provisions as the parents, children, and grandparents of an honoree who has paid for or contracted to pay for any item, service, or location covered under the policy.
                            <br/><br/>It is important to keep all receipts and contracts in case you must file a claim.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Is there coverage if the honoree(s) cannot make the ceremony?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Yes, reimbursement is available if a necessary and unavoidable cancellation or postponement of the event occurs. Reimbursement is available for transportation, food, catering services, property and equipment rentals, hall and location rentals, and more. Refer to the policy for full details regarding exclusions and limitations of coverage.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Does wedding insurance provide coverage for illness or injury to the honoree?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Yes. Coverage will apply if there is an unexpected illness, injury or death that prevents the appearance of the honorees, parents, grandparents and children of the honorees or ceremony officiates. Refer to the policy for full details regarding exclusions of coverage with respect to pre-existing conditions.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Am I covered in case of bad weather on my wedding day?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        The Wedding Protector Plan makes a distinction between a rainy day and truly severe or catastrophic weather, such as a hurricane. If catastrophic weather causes you to postpone the wedding, we can potentially provide coverage for expenses incurred, but not reimbursed.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Does the Wedding Protector Plan Wedding Insurance provide any coverage if I cannot go on my honeymoon?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            If your wedding is canceled due to a necessary and unavoidable covered reason, any non-recoverable travel and accommodations for your honeymoon may be covered under the Cancellation / Postponement coverage up to the covered limit on the policy.
                            <br/><br/><i>Postponement or cancellation of the honeymoon for any reason other than cancellation or postponement of the wedding will not be covered under the Wedding Protector Plan.</i>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Does the Wedding Protector Plan insurance have a deductible?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            No, there is no deductible with our insurance coverage. Many of the other event insurance providers may require deductibles.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        )
    }
}

export default Accordion01;





