import React from 'react';
import {FaInstagram ,FaFacebookF} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_color--6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row" >
                    <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                        <a href='/terms'>Terms of Service</a>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="text">
                                <p>Copyright © 2023 Magnificent Moments Photography LLC</p>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;