import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Contact from "../elements/contact/ContactThree";
import ServiceTwo from "../elements/service/ServiceAlt";
import Breadcrumb from "../elements/common/Breadcrumb";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const TabOne = [
    {
        image: '1',
        bigImage: '/assets/images/portfolio/family/familygallerylg-1.jpg',
    },
    {
        image: '2',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
    },
    {
        image: '3',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-03.jpg',
    },
    {
        image: '4',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-04.jpg',
    },
]


class HomePortfolio extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tab1: 0,
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { tab1, isOpen } = this.state;
        return(
            <div>
                <Helmet pageTitle="Graduation" canonical="https://magnificentphotosnow.com/graduation" />
                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Graduation'}   />
                {/* End Breadcrump Area */}

                <div className="page-wrapper">
                    {/* Start Portfolio Area  */}
                <div className="rn-portfolio-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <span className="subtitle">Completed photos</span>
                                        <h2 className="title">Graduation Gallery</h2>
                                        <p className="description">Step into a world where moments come alive through the lens</p>
                                    </div>
                                </div>
                        </div>
                        <div className="row">
                            {TabOne.map((value , index) => (
                                <div className="col-lg-6" key={index}>
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={TabOne[tab1].bigImage}
                                            nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                            prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                            onMovePrevRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                            })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    tab1: (tab1 + 1) % TabOne.length,
                                                })
                                            }
                                        />
                                    )}
                                    <div className="item-portfolio-static">
                                        <div>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <a>
                                                            <img src={`/assets/images/portfolio/graduation/graduationgallerysm-${value.image}.jpg`} alt="Portfolio Images"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/graduationgallery"><span>View More Photos</span></a>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}
                </div>

                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                            <ServiceTwo />
                    </div>
                </div>               

                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Popular Packages</span>
                                    <h2 className="title">Starting Prices</h2>
                                    <p>Prices vary based on addons and location</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Premium</h4>
                                            <div className="pricing">
                                                <span className="price">20 Min</span>
                                                <span className="subtitle">Coverage</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 5 digital images</li>
                                                <li><FiCheck /> 6 month cloud storage</li>
                                                <li><FiCheck /> 5-7 business day delivery</li>
                                            </ul>
                                        </div>
                                        {/* <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Book Now</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Premium Plus</h4>
                                            <div className="pricing">
                                                <span className="price">30 Min</span>
                                                <span className="subtitle">Coverage</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 10 digital images</li>
                                                <li><FiCheck /> 6 month cloud storage</li>
                                                <li><FiCheck /> 5-7 business day delivery</li>
                                            </ul>
                                        </div>
                                        {/* <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Book Now</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Prestige</h4>
                                            <div className="pricing">
                                                <span className="price">60 Min</span>
                                                <span className="subtitle">Coverage</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 15 digital images</li>
                                                <li><FiCheck /> 6 month cloud storage</li>
                                                <li><FiCheck /> 5-7 business day delivery</li>
                                            </ul>
                                        </div>
                                        {/* <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Book Now</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--5">
                    <Contact />
                </div>
                {/* End Contact Page Area  */}


                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </div>
            
        )
    }
}
export default HomePortfolio;