import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Contact from "../elements/contact/ContactThree";
import Breadcrumb from "../elements/common/Breadcrumb";
import FAQ from "../elements/Accordion";



class HomePortfolio extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tab1: 0,
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div>
                <Helmet pageTitle="Terms" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Terms'}   />
                {/* End Breadcrump Area */}

                <div className="page-wrapper">

                        {/* Start Columns Area  */}
                            <div className="rn-columns-area ptb--120 bg_color--5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="single-column">
                                                <h4 className="tilte">Terms of Service</h4>
                                                <p className="description mt--30">Thank you for trusting your photo session with Magnificent Moments Photography. By doing business with Magnificent Moments Photography LLC, you accept the Terms of Sale set forth below. Please read through our terms thoroughly. These items may be updated at any time; please check back for updates. The website resides at www.magnificentphotosnow.com.</p>
                                                <p className="description mt--30">Planning a wedding for yourself or a loved one is something you have dreamed about forever, and one of the most important days of your life. We are committed to help you protect your financial investments so that your wedding day is everything it should be.</p>
                                            </div>

                                            <div className="single-column mt--30">
                                                <h4 className="tilte">Copyrights and Trademarks.</h4>
                                                <p className="description mt--30">All materials appearing on www.magnificentphotosnow.com are the property of Magnificent Moments Photography LLC. Materials protected by this copyright include all images and all text; (including logos and other graphics); and all design elements of the website. You may use the content of magnificentphotosnow.com for shopping, placing an order on this site, or for conducting other tasks associated directly with, and for the sole purpose of, shopping on this site or placing an order on this site. Unauthorized use of the materials appearing on magnificentphotosnow.com may violate copyright, trademark, and other applicable laws and could result in criminal or civil penalties. Such unauthorized use includes, but is not exclusive to, copying, reproducing, republishing, modifying, uploading, transmitting, or distributing any of such materials without the prior written consent of Magnificent Moments Photography LLC. The “Magnificent Moments Photography LLC” logo is a trademark of Magnificent Moments Photography LLC, as are all custom graphics, icons, and logos found on www.magnificentphotosnow.com.</p>
                                            </div>

                                            <div className="single-column mt--30">
                                                <h4 className="tilte">Jurisdiction.</h4>
                                                <p className="description mt--30">Magnificent Moments Photography LLC is a Limited Liability Company with its principal place of business in Shelby County, Alabama and you agree that any legal action brought against Magnificent Moments Photography LLC shall be governed by the laws of the state of Alabama without regard to its conflict of law principles. Additionally, you agree that all disagreements arising from your use of our services here at Magnificent Moments Photography LLC will be adjudicated in the county of Shelby, Alabama by an appropriate federal or state court. </p>
                                            </div>

                                            <div className="single-column mt--30">
                                                <h4 className="tilte">Appointment Agreement.</h4>
                                                <p className="description mt--30">By booking an appointment with Magnificent Moments Photography LLC, you agree that the date and time specified is the date and time we will honor. All sessions can be canceled within 72 hours from when the deposit is made. Sessions canceled within 72 hours from when the deposit is made will receive a Full Refund. No refunds will be issued for any sessions canceled after 72 hours from when the deposit is made. We book sessions weeks and even months in advance. The no refund after 72 hours from when the session was booked is in place to compensate for any sessions we had to deny due to the time and date being reserved. If your appointment needs to be modified or canceled, this must be done within 72 hours of the scheduled appointment time. Any modifications within 72 hours of the scheduled appointment time may result in a 25% fee added to your overall service cost. We will work to find a new date and time that is available within our schedule. If you prepay for the session, the same 72 hour window applies for refunds. Refunds will only be given if the cancellation is requested within 72 hours after the payment is made. Your appointment time will be strictly adhered to. If for some reason you are late, the photographer has the right to end the session at the originally scheduled end time. If for some reason we are late, we will extend the session time to give you the time originally agreed upon.</p>
                                            </div>

                                            <div className="single-column mt--30">
                                                <h4 className="tilte">Photo Release Agreement.</h4>
                                                <p className="description mt--30">By booking an appointment with Magnificent Moments Photography LLC, you agree that all photos/videos taken can be utilized by Magnificent Moments Photography LLC and our subsidiaries. Photo content used will only be used for promotional purposes. </p>
                                            </div>

                                            <div className="single-column mt--30">
                                                <h4 className="tilte">Content Delivery.</h4>
                                                <p className="description mt--30">Magnificent Moments Photography LLC will deliver all content via Digital link. Photos are delivered in two formats. One for printing purposes and one for Social Media purposes. Content will remain accessible to the client for 72 hours upon photo completion. Once this period has expired, the link will be inaccessible. We encourage clients to download all content as soon as possible.</p>
                                            </div>

                                            <div className="single-column mt--30">
                                                <h4 className="tilte">Privacy & Security Policies.</h4>
                                                <p className="description mt--30">Overview Magnificent Moments Photography LLC does not rent, trade, loan, or sell your personal information or any other information which may identify you, to third parties. We are committed to protecting our customers’ privacy. The information we collect is used to process your order and to enhance your shopping and servicing experience.</p>
                                            </div>

                                            <div className="single-column mt--30">
                                                <h4 className="tilte">Your Personal Information.</h4>
                                                <p className="description mt--30">When you place an order at our site, you provide us with your name, email address, credit card information, address, and phone number. We use this information to process your order. The financial information (credit card number, expiration date, billing address, etc.) that we collect from you is used for billing purposes only.</p>
                                            </div>

                                            <div className="single-column mt--30">
                                                <h4 className="tilte">Client Consent.</h4>
                                                <p className="description mt--30">By using our website, you are agreeing to let us collect the aforementioned information (please see the above) and use it in the said manner. If we decide to change our privacy policy, we will post those changes on this page. Magnificent Moments Photography LLC will cooperate with law enforcement agencies to identify any individual who attempts to use our services for illegal activities. We will also report any activity we believe to be unlawful, to the proper authorities. Additionally, we may release account information when we feel that such a release is necessary to enforce the terms of our user agreement, or to protect the rights, property, and safety of our users, Magnificent Moments Photography LLC, or others. Other sites accessible through Magnificent Moments Photography LLC have their privacy policies and data collection practices. Please consult each site’s privacy policy. Magnificent Moments Photography LLC is not responsible for the actions of third parties.</p>
                                            </div>

                                            <div className="single-column mt--30">
                                                <h4 className="tilte">Wedding Insurance.</h4>
                                                <p className="description mt--30">Magnificent Moments Photography offers wedding insurance as a service to its clients. This insurance is provided by a third-party agency and is subject to their terms and conditions.</p>
                                                <p className="description mt--10"><b>Limitation of Liability</b>: Magnificent Moments Photography shall not be held liable for any denials of claims or denial of coverage under the wedding insurance policy. The client understands and acknowledges that the insurance coverage is solely the responsibility of the third-party agency and any disputes or issues regarding claims or coverage should be directed to the insurance provider.</p>
                                                <p className="description mt--10"><b>Insurance Provider's Terms</b>: The client agrees to familiarize themselves with the terms and conditions of the insurance policy provided by the third-party agency. Magnificent Moments Photography shall not be responsible for any discrepancies, limitations, or exclusions within the insurance policy.</p>
                                                <p className="description mt--10"><b>Claims and Disputes</b>: In the event of a claim or dispute related to the wedding insurance, the client agrees to resolve the matter directly with the third-party insurance provider. Magnificent Moments Photography may provide reasonable assistance and documentation as requested by the client or the insurance provider to facilitate the claims process but shall not be responsible for the final decision or outcome of the claim.</p>
                                                <p className="description mt--10"><b>Indemnification</b>: The client agrees to indemnify and hold Magnificent Moments Photography harmless from any claims, losses, damages, liabilities, or expenses arising from or in connection with the wedding insurance coverage provided by the third-party agency, including but not limited to denials of claims or denial of coverage.</p>
                                                <p className="description mt--10"><b>Modifications</b>: Magnificent Moments Photography reserves the right to modify or update these terms and conditions related to wedding insurance without prior notice. The client is encouraged to review the terms periodically for any changes.</p>
                                                <p className="description mt--10">By continuing to use the wedding insurance service offered by Magnificent Moments Photography, the client acknowledges and agrees to these additional terms and conditions related to the third-party insurance agency.</p>
                                                <p className="description mt--10">If you have any questions about this privacy statement, Magnificent Moments Photography LLC’s practices, or your business with Magnificent Moments Photography LLC, please contact Magnificent Moments Photography LLC. Contact information can be found at www.magnificentphotosnow.com Please check back occasionally for any updates or changes to our privacy policy.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* Start Columns Area  */}

                </div>


                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </div>
            
        )
    }
}
export default HomePortfolio;