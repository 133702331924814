import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Contact from "../elements/contact/ContactThree";
import ServiceTwo from "../elements/service/ServiceAlt";
import Breadcrumb from "../elements/common/Breadcrumb";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const TabOne = [
    {
        image: '1',
        bigImage: '/assets/images/portfolio/family/familygallerylg-1.jpg',
    },
    {
        image: '2',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
    },
    {
        image: '3',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-03.jpg',
    },
    {
        image: '4',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-04.jpg',
    },
]


class HomePortfolio extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tab1: 0,
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { tab1, isOpen } = this.state;
        return(
            <div>
                <Helmet pageTitle="Contact" canonical="https://magnificentphotosnow.com/contact"/>
                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Contact'}   />
                {/* End Breadcrump Area */}
       

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--5">
                    <Contact />
                </div>
                {/* End Contact Page Area  */}


                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </div>
            
        )
    }
}
export default HomePortfolio;