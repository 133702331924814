import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { FiCheck } from "react-icons/fi";

class Accordion01 extends Component {
    render(){
        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
        ];

        return(
            <Accordion className="accodion-style--1" preExpanded={'0'}>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What if I am late for my session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Due to the nature of these sessions, the session will be on a strict schedule. This means the session will start at the time selected and end at the specified time. If you are late, we will work with you to try and reschedule or, we will use the remaining time we have available in the sessions to complete your photos. 
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How Long is Each Photo Session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        The photo sessions are 15 minutes each.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What Should We Wear for a Fall Photo Session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        For the optimal color contrast, we recommend white, deep red, burnt orange, golden yellow, warm brown, or dark green colors. These colors will help you to stand out in the photos more.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How many people can be apart of my session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        These sets are designed for families of 6 or less, however the flexibility is there. We can certainly make larger families work in these sessions. This way, no one is left out.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What Happens in Case of Bad Weather?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        We can never predict the weather. In the event of bad weather, we will offer the option to reschedule the session for another date that we have available.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How Long After the Session Will We Receive Our Photos?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Once your photo session is complete, you will receive your gallery within 48 hours of the session. You will then select your photo choices. Once we have your photo choices, we will ensure your images are edited and delivered to you digitally within 3-5 business days.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>


                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Can We Purchase Additional Photos Beyond the Promo Package?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        When you see additional images that you absolutely love, you can obtain those images at an additional cost. Let us know which ones you like, and we can assist in getting those additional images edited and delivered to you.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Do You Offer Photo Retouching or Editing Services?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        All of our photo sessions receive our 15+ years of editing experience. We aim to deliver nothing but the best to our clients.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How Do We Prepare for the Session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        These sessions are meant to be fun and breezy. Definitely plan out your outfit choices and put together any specific poses you may want personally. This way we can incorporate your ideas in with ours.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        What's Your Cancellation or Rescheduling Policy?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        We understand life happens. When you book your session, you have 72 hours from the moment the booking is paid to request for a refund. Refunds/cancellations will not be given after the 72 hour window has passed.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Are Pets Allowed in the Session?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Absolutely! Fall portraits are best when the entire family is included, so feel free to bring your fur babies. 
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        )
    }
}

export default Accordion01;





