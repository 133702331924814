import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PortfolioMasonry from "../elements/portfolio/PortfolioOurPhotography";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Contact from "../elements/contact/ContactThree";
import ServiceTwo from "../elements/service/ServiceHome";
import ModalVideo from 'react-modal-video';

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Memories',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

class HomePortfolio extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div className="active-dark bg_color--10"> 
                <Helmet pageTitle="Magnificent Moments" description="Magnificent Moments Photography, where we specialize in capturing the most precious milestones of your life. From dreamy wedding ceremonies and heartwarming family portraits to unforgettable graduation moments and professional business photo and video, our team of skilled professionals is dedicated to preserving your cherished memories with creativity, expertise, and a touch of magic." keywords="Wedding photographer, Family photographer, Graduation photographer, Video production services, Corporate photography, Wedding photography packages, Family portrait session, Graduation photo shoot, Professional videography, Corporate event photography, Wedding photojournalism, Family photography studio, Graduation ceremony coverage, Video editing services, Corporate headshots" canonical="https://magnificentphotosnow.com/" />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--24" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12" style={{marginTop: "80px"}}>
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" style={{textShadow: "0 1px 1px black"}}>Timeless <span className="theme-gradient" style={{textShadow: "none"}}>{value.title}</span></h1> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--10 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Completed portfolios</span>
                                    <h2 className="title">Our Photography</h2>
                                    <p className="description">Step into a world where moments come alive through the lens</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <PortfolioMasonry item="8" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover m-md-auto" />
                        </div>
                    </div>
                </div>
                {/* End portfolio Area  */}

                <div className="service-area pb--120 bg_color--10">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>

                {/* Start About Video Area  */}

                <div className="rn-brand-area pb--120 bg_color--5 mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                <span className="subtitle">Behind the Magic</span>
                                    <h2 className="title">Who we are</h2>
                                </div>
                            </div>
                        </div>
                        <div className="video-image-wrapper mt--30">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-10 offset-lg-1">
                                        <div className="thumb position-relative">
                                            <img className="w-100" src="/assets/images/portfolio/wedding/aboutvideoimg.jpg" alt="Service Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='YOFG1mOfRvM' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div> 
                    </div>
                </div>
 
                {/* End About Video Area  */}

                    {/* Start Team Area  */}
                    <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25">
                                    <span className="subtitle">Skilled team</span>
                                    <h2 className="title">Our Team</h2>
                                    <p className="description">With over 12 years of combined dedication, <br/> our expertise shines through in every frame we capture</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/darrianprofile.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Darrian</h4>
                                        <p className="designation">Lead Photographer</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/yareliprofile.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Magda</h4>
                                        <p className="designation">Lead Videographer</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/kendriya-image.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Kendriya</h4>
                                        <p className="designation">Photographer</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Team  */}

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}


                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <Contact />
                </div>
                {/* End Contact Page Area  */}


                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}
export default HomePortfolio;