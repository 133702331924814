import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Contact from "../elements/contact/ContactThree";
import Breadcrumb from "../elements/common/BreadcrumbWeddingGallery";
import FAQ from "../elements/Accordion";



class HomePortfolio extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tab1: 0,
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div>
                <Helmet pageTitle="Wedding Coverage" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Wedding Coverage'}   />
                {/* End Breadcrump Area */}

                <div className="page-wrapper">

                        {/* Start Columns Area  */}
                            <div className="rn-columns-area ptb--120 bg_color--5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="single-column">
                                                <h4 className="tilte">Why Wedding Insurance</h4>
                                                <p className="description mt--30">If your wedding is canceled due to a necessary and unavoidable covered reason, any non-recoverable travel and accommodations for your honeymoon may be covered under the Cancellation/Postponement coverage up to the covered limit on the policy.</p>
                                                <p className="description mt--30">Planning a wedding for yourself or a loved one is something you have dreamed about forever, and one of the most important days of your life. We are committed to help you protect your financial investments so that your wedding day is everything it should be.</p>
                                                <p className="description mt--30">The Wedding Protector Plan provides private event or wedding insurance coverage for the wedding ceremony, reception, rehearsal and rehearsal dinner at a reasonable price with no deductible.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* Start Columns Area  */}

                    {/* Start FAQ  */}
                    <div className="rn-columns-area ptb--120 bg_color--5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="about-inner inner">
                                            <div className="section-title text-left pb--30">
                                                <span class="subtitle theme-gradient">Check out our FAQ section to see the benefit of Wedding Coverage</span>
                                                <h2 className="title">FAQ</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="faq-area">
                                            <FAQ />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* Start FAQ  */}

                </div>


            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--5">
                    <Contact />
                </div>
                {/* End Contact Page Area  */}


                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </div>
            
        )
    }
}
export default HomePortfolio;