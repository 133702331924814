import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Contact from "../elements/contact/ContactThree";
import ServiceTwo from "../elements/service/ServiceHome";
import Breadcrumb from "../elements/common/Breadcrumb";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function handleClick(evt) {
    document.querySelector('[id="contact"]').scrollIntoView();
  }


const TabOne = [
    {
        image: '1',
        bigImage: '/assets/images/portfolio/wedding/weddingpre-1.jpg',
    },
    {
        image: '2',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
    },
    {
        image: '3',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-03.jpg',
    },
    {
        image: '4',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-04.jpg',
    },
]


class HomePortfolio extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tab1: 0,
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { tab1, isOpen } = this.state;
        var namesItemOne = [
            'Personal wedding website',
            'Countdown to your wedding day',
            'Images to show case your engagement',
            'Meet the couple section',
        ];
        return(
            <div>
                <Helmet pageTitle="Weddings" canonical="https://magnificentphotosnow.com/wedding" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Weddings'}   />
                {/* End Breadcrump Area */}

                <div className="page-wrapper">
                    {/* Start Portfolio Area  */}
                <div className="rn-portfolio-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <span className="subtitle">Completed photos</span>
                                        <h2 className="title">Wedding Gallery</h2>
                                        <p className="description">Step into a world where moments come alive through the lens</p>
                                    </div>
                                </div>
                        </div>
                        <div className="row">
                            {TabOne.map((value , index) => (
                                <div className="col-lg-6" key={index}>
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={TabOne[tab1].bigImage}
                                            nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                            prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                            onMovePrevRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                            })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    tab1: (tab1 + 1) % TabOne.length,
                                                })
                                            }
                                        />
                                    )}
                                    <div className="item-portfolio-static">
                                        <div>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <a>
                                                            <img src={`/assets/images/portfolio/wedding/weddingpre-${value.image}.jpg`} alt="Portfolio Images"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/weddinggallery"><span>View More Photos</span></a>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}
                </div>

                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                            <ServiceTwo />
                    </div>
                </div>

                {/* Start About Area  */}
                <div className="rn-about-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/weddingwebsiteDemo.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <span className="subtitle">Wedding Website</span>
                                        <h2 className="title">Announce Your Big Day</h2>
                                        <p>Our complimentary wedding website service is a valuable addition to our wedding photo packages. It features a user-friendly interface for effortless design, and an integrated gallery to display stunning photos</p>
                                        <p>Additional features include: </p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                    <div className="about-button mt--30">
                                        <a className="btn-default" href="https://mywedding.magnificentphotosnow.com/" target="_blank">See Example</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">Wedding Insurance</h2>
                                                    <p className="description mt--30">You've always dreamed of the perfect wedding, and no matter how carefully you plan it, things can happen. A wedding is an investment and we want to help you protect that investment.</p>
                                                    <p className="description">Each of our wedding packages offers a certain level of wedding insurance at no additional cost to our clients. This will ensure that you not only have beautiful photos, but the piece of mind that your expenses are covered in the event of unexpected events.</p>
                                                </div>
                                                <div className="portfolio-details-btn mt--30">
                                                    <a className="btn-default btn-border" href="/weddingcoverage">View More Photos</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Coverage</h4> 
                                                        <p className="description mt--30">Wedding insurance helps make things right when something goes wrong. Coverages include but are not limited to the following</p>
                                                        <ul className="list-style--1">
                                                            <li><FiCheck /> Wedding cancellation</li>
                                                            <li><FiCheck /> Lost or stolen wedding dress or rings</li>
                                                            <li><FiCheck /> Lost deposits</li>
                                                            <li><FiCheck /> And more</li>
                                                        </ul>
                                                        <p className="description mt--30">We partner with a local Insurance Agency to ensure all policies are explained in detailed and that your planned wedding qualifies for coverage. Certain limitations do apply. All wedding coverages are subjected to approval.</p>     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}
                

                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Popular Packages</span>
                                    <h2 className="title">Starting Prices</h2>
                                    <p>Prices vary based on addons and location</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Premium</h4>
                                            <div className="pricing">
                                                <span className="price">4 Hour</span>
                                                <span className="subtitle">Coverage</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 150 digital images</li>
                                                <li><FiCheck /> 2-3 min short film</li>
                                                <li><FiCheck /> Wedding website</li>
                                                <li><FiCheck /> Up to $7,500 coverage</li>
                                                <li><FiCheck /> 10-14 business day delivery</li>
                                            </ul>
                                        </div>
                                        {/* <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Book Now</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Premium Plus</h4>
                                            <div className="pricing">
                                                <span className="price">5 Hour</span>
                                                <span className="subtitle">Coverage</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 300 digital images</li>
                                                <li><FiCheck /> 3-5 min short film</li>
                                                <li><FiCheck /> Wedding website</li>
                                                <li><FiCheck /> Up to $15,000 coverage</li>
                                                <li><FiCheck /> 10-14 business day delivery</li>
                                            </ul>
                                        </div>
                                        {/* <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Book Now</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Prestige</h4>
                                            <div className="pricing">
                                                <span className="price">6 Hour</span>
                                                <span className="subtitle">Coverage</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> 400 digital images</li>
                                                <li><FiCheck /> 4-6 min short film</li>
                                                <li><FiCheck /> Wedding website</li>
                                                <li><FiCheck /> Up to $25,000 coverage</li>
                                                <li><FiCheck /> 10-14 business day delivery</li>
                                            </ul>
                                        </div>
                                        {/* <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Book Now</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--5">
                    <Contact />
                </div>
                {/* End Contact Page Area  */}


                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </div>
            
        )
    }
}
export default HomePortfolio;