import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Contact from "../elements/contact/ContactThree";
import ServiceTwo from "../elements/service/ServiceVideography";
import Breadcrumb from "../elements/common/Breadcrumb";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function handleClick(evt) {
    document.querySelector('[id="contact"]').scrollIntoView();
  }


const TabOne = [
    {
        image: '1',
        bigImage: '/assets/images/portfolio/wedding/weddingpre-1.jpg',
    },
    {
        image: '2',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
    },
    {
        image: '3',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-03.jpg',
    },
    {
        image: '4',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-04.jpg',
    },
]


class HomePortfolio extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tab1: 0,
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { tab1, isOpen } = this.state;
        var namesItemOne = [
            'Personal wedding website',
            'Countdown to your wedding day',
            'Images to show case your engagement',
            'Meet the couple section',
        ];
        return(
            <div>
                <Helmet pageTitle="Videography" canonical="https://magnificentphotosnow.com/videography" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Videography'}   />
                {/* End Breadcrump Area */}

                <div className="page-wrapper">
                    {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">Our Videography</h2>
                                                    <p className="description mt--30">Capture the magic of your story with our exceptional video services. At Magnificent Moments, we're passionate about creating captivating visuals that bring your vision to life. Whether you need a promotional video that grabs attention, a heartfelt wedding film that preserves your most cherished memories, or a corporate video that highlights your unique brand identity, we've got you covered.</p>
                                                    <p className="description">We blend artistry with technical expertise to produce videos that are visually stunning, emotionally resonant, and reflect your unique style and message.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Comprehensive Services</h4> 
                                                        <p className="description mt--30">We carefully craft every frame, every shot, and every sequence to deliver a visual masterpiece that resonates with your audience. From concept development to post-production, we meticulously work to ensure that your story is told in the most compelling and impactful way possible.<br/><br/>We accomplish this with:</p>
                                                        <ul className="list-style--1">
                                                            <li><FiCheck /> Engaging Scripts</li>
                                                            <li><FiCheck /> Beautiful Visuals</li>
                                                            <li><FiCheck /> Crips Clean Audio</li>
                                                            <li><FiCheck /> And more</li>
                                                        </ul>
                                                        <p className="description mt--30">Collaboration lies at the heart of our process. We listen to your ideas, understand your goals, and tailor our approach to match your vision. Our team is dedicated to providing a seamless and enjoyable experience from start to finish, ensuring that the process is as enjoyable as the final result.</p>     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}
                
                </div>

                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                            <ServiceTwo />
                    </div>
                </div>

            {/* Start Pricing Tbale Area  */}
            <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Video Capturing Process</span>
                                    <h2 className="title">Our Workflow</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Pre-Production</h4>
                                            <div className="pricing">
                                                <span className="price">Step 1</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Scripting/Writing</li>
                                                <li><FiCheck /> Storyboarding</li>
                                                <li><FiCheck /> Talent Recruitment</li>
                                                <li><FiCheck /> Shot List and Schedule</li>
                                            </ul>
                                        </div>
                                        {/* <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Book Now</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Production</h4>
                                            <div className="pricing">
                                                <span className="price">Step 2</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Set Preparation</li>
                                                <li><FiCheck /> Practice Runs</li>
                                                <li><FiCheck /> Production Filming</li>
                                                <li><FiCheck /> B-roll Capture</li>
                                            </ul>
                                        </div>
                                        {/* <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Book Now</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Post-Production</h4>
                                            <div className="pricing">
                                                <span className="price">Step 3</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Video Editing</li>
                                                <li><FiCheck /> Color Grading</li>
                                                <li><FiCheck /> Audio Production</li>
                                                <li><FiCheck /> Review/Revise</li>
                                            </ul>
                                        </div>
                                        {/* <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Book Now</a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}    
                

            {/* Start Contact Page Area  */}
            <div className="rn-contact-page ptb--120 bg_color--5">
                    <Contact />
                </div>
                {/* End Contact Page Area  */}


                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </div>
            
        )
    }
}
export default HomePortfolio;