import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck, FiMinus } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import HowToBook from "../elements/service/ServicePromo";
import Breadcrumb from "../elements/common/Breadcrumb";
import Lightbox from 'react-image-lightbox';
import FAQ from "../elements/PromoFAQ";
import 'react-image-lightbox/style.css';


const TabOne = [
    {
        image: '1',
        bigImage: '/assets/images/portfolio/promo/promo-1.jpg',
    },
    {
        image: '2',
        bigImage: '/assets/images/portfolio/promo/promo-2.jpg',
    },
    {
        image: '3',
        bigImage: '/assets/images/portfolio/promo/promo-3.jpg',
    },
    {
        image: '4',
        bigImage: '/assets/images/portfolio/promo/promo-4.jpg',
    },
]


class HomePortfolio extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tab1: 0,
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const { tab1, isOpen } = this.state;
        var namesItemOne = [
            'Personal wedding website',
            'Countdown to your wedding day',
            'Images to show case your engagement',
            'Meet the couple section',
        ];
        return(
            <div>
                <Helmet pageTitle="Promotions" canonical="https://magnificentphotosnow.com/promotions" />

                {/* Start Header Area  */}
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* End Header Area  */}
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Fall Promotion'}   />
                {/* End Breadcrump Area */}

                <div className="page-wrapper">
                    {/* Start Portfolio Area  */}
                <div className="rn-portfolio-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <span className="subtitle">Completed photos</span>
                                        <h2 className="title">Fall Gallery</h2>
                                        <p className="description">Embrace the beauty of Fall with our special photography package.</p>
                                    </div>
                                </div>
                        </div>
                        <div className="row">
                            {TabOne.map((value , index) => (
                                <div className="col-lg-6" key={index}>
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={TabOne[tab1].bigImage}
                                            nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                                            prevSrc={TabOne[(tab1 + TabOne.length - 1) % TabOne.length]}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                            onMovePrevRequest={() =>
                                            this.setState({
                                                tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                                            })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    tab1: (tab1 + 1) % TabOne.length,
                                                })
                                            }
                                        />
                                    )}
                                    <div className="item-portfolio-static">
                                        <div>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <a>
                                                            <img src={`/assets/images/portfolio/promo/promo-${value.image}.jpg`} alt="Portfolio Images"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}
                </div>

                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center mb--30">
                                            <span className="subtitle">Photo Package</span>
                                            <h2 className="title">Session Details</h2>
                                            <p className="description">Exclusive Fall Photo Package Available Now.</p>
                                        </div>
                                    </div>
                        </div>
                        <div className="row">
                             {/* Start Portfolio Details */}
                            <div className="rn-portfolio-details bg_color--5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="portfolio-details">
                                                <div className="row row--35 mb--50">
                                                    <div className="col-lg-6">
                                                        <div className="inner">
                                                            <div className="section-title">
                                                                <h2 className="title">Location &amp;  Dates</h2>
                                                                <p className="description mt--30">
                                                                    <b>Location: Shoal Creek Park </b><br/>
                                                                    Sessions will be captured at 2781 Hwy 119, Montevallo, AL 35115
                                                                </p>
                                                                <p className="description">
                                                                    <b>Dates:</b>
                                                                    <ul className="list-style--1 mt-2">
                                                                        <li><FiMinus /> Sunday 10/15: 4:00 PM - 6:00 PM - Full</li>
                                                                        <li><FiMinus /> Saturday 10/21: 4:00 PM - 6:00 PM - Full</li>
                                                                        <li><FiCheck /> Sunday 10/22: 4:00 PM - 6:00 PM - Available</li>
                                                                        <li><FiMinus /> Saturday 10/28: 4:00 PM - 6:00 PM - Full</li>
                                                                        <li><FiCheck /> Sunday 10/29: 4:00 PM - 6:00 PM - Available</li>
                                                                    </ul>
                                                                </p>
                                                                <div className="portfolio-details-btn mt--30">
                                                                    <a className="btn-default btn-border" href="https://squareup.com/appointments/book/f6bkeadeo5y1ba/X28Y46024049F/start" rel="noopener noreferrer" target="_blank">Book Your Spot</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                            <div className="inner">
                                                                <div className="section-title">
                                                                    <h4 className="title">Package Details</h4> 
                                                                    <p className="description mt--30">The session will include our 2023 Fall set. You are welcome to bring additional props that you may want to use in your session.</p>
                                                                    <p className="description mt--30">
                                                                        <b>Fall Promo</b>
                                                                        <ul className="list-style--1">
                                                                            <li><FiMinus /> 15 Minutes Session</li>
                                                                            <li><FiMinus /> 10 High Quality Edits</li>
                                                                            <li><FiMinus /> Delivered Digitally</li>
                                                                            <li><FiMinus /> Additional Photos Optional</li>
                                                                            <li><FiMinus /> Delivered in 3-5 Business days</li>
                                                                        </ul>
                                                                    </p> 
                                                                    <h4 className="title">Total: $100</h4>     
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Portfolio Details */}

                        </div>
                    </div>
                </div>

                

                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                            <HowToBook />
                            <div className="portfolio-details-btn mt--30 text-center">
                                 <a className="btn-default btn-border" href="https://squareup.com/appointments/book/f6bkeadeo5y1ba/X28Y46024049F/start" rel="noopener noreferrer" target="_blank">Book Your Spot</a>
                            </div>
                    </div>
                </div>               


                {/* Start FAQ  */}
                <div className="rn-columns-area ptb--120 bg_color--5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="about-inner inner">
                                            <div className="section-title text-left pb--30">
                                                <h2 className="title">FAQ</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="faq-area">
                                            <FAQ />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* Start FAQ  */}

                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </div>
            
        )
    }
}
export default HomePortfolio;