// React Required
import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';
 import Index from './dark/index';
 import Wedding from './dark/Wedding';
 import WeddingGallery from './dark/WeddingGallery';
 import WeddingCoverage from './dark/WeddingCoverage';
//  import WeddingWebsite from './dark/WeddingWebsite';
 import Family from './dark/Family';
 import Promotions from './dark/Promotions';
 import FamilyGallery from './dark/FamilyGallery';
 import Graduation from './dark/Graduation';
 import GraduationGallery from './dark/GraduationGallery';
 import Videography from './dark/Videography';
 import Contact from './dark/Contact';
 import Terms from './dark/Terms';

//  import Resources from './dark/Resources';
//  import WeddingResources from './dark/WeddingResources';
//  import ChoosingThePerfectWeddingPhotographer from './dark/WeddingResources/ChoosingThePerfectWeddingPhotographer';
//  import HowToPrepareForYourEngagementPhotoShoot from './dark/WeddingResources/HowToPrepareForYourEngagementPhotoShoot';
//  import WhyProfessionalPhotoEditingMattersInWeddingPhotography from './dark/WeddingResources/WhyProfessionalPhotoEditingMattersInWeddingPhotography';
//  import HowToPoseNaturalInYourWeddingPhotos from './dark/WeddingResources/HowToPoseNaturalInYourWeddingPhotos';

import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

ReactGA.initialize("UA-153644967-1");
ReactGA.pageview(window.location.pathname + window.location.search);


class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path='/' component={Index}/>
                        <Route path='/wedding' component={Wedding}/>
                        <Route exact path='/weddinggallery' component={WeddingGallery}/>
                        <Route exact path='/weddingcoverage' component={WeddingCoverage}/>
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/weddingwebsite`} component={WeddingWebsite}/> */}
                        <Route exact path='/promotions' component={Promotions}/>
                        <Route exact path='/family' component={Family}/>
                        <Route exact path='/familygallery' component={FamilyGallery}/>
                        <Route exact path='/graduation' component={Graduation}/>
                        <Route exact path='/graduationgallery' component={GraduationGallery}/>
                        <Route exact path='/videography' component={Videography}/>
                        <Route exact path='/contact' component={Contact}/>
                        <Route exact path='/terms' component={Terms}/>

                        {/* Resources Page */}
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/resources`} component={Resources}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/weddingresources`} component={WeddingResources}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/weddingresources/ChoosingThePerfectWeddingPhotographer`} component={ChoosingThePerfectWeddingPhotographer}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/weddingresources/HowToPrepareForYourEngagementPhotoShoot`} component={HowToPrepareForYourEngagementPhotoShoot}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/weddingresources/WhyProfessionalPhotoEditingMattersInWeddingPhotography`} component={WhyProfessionalPhotoEditingMattersInWeddingPhotography}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/weddingresources/HowToPoseNaturalInYourWeddingPhotos`} component={HowToPoseNaturalInYourWeddingPhotos}/> */}
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();